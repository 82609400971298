
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background-color: var(--chakra-colors-primary-600);
  position: fixed;
  box-shadow: none;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
